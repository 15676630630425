import React from 'react'
import { Table, message } from 'antd'
import { useTranslation } from 'react-i18next'
import {
	SortableContainer,
	SortableElement,
	SortableHandle,
} from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import './CategoryList.css'
import { useDispatch } from 'react-redux'
import {
	getCategory,
	UpdateIndexCategory,
} from '../../../../store/actions/category'

const CategoryList = (props) => {
	const { t } = useTranslation()

	const SortableItem = SortableElement((props) => <tr {...props} />)
	const SortableBody = SortableContainer((props) => <tbody {...props} />)

	const DragHandle = SortableHandle(() => {
		return (
			<div style={{ textAlign: 'center', cursor: 'grab' }}>
				<img src='/assets/OtherIcons/drag_icon_gray.svg' />
			</div>
		)
	})

	const messageSucces = (value) => {
		value === 'updateCategoryIndex' ?
			message.success(t('IndexUpdateCategoryMessageSucces')) :
			value === 'updateProductsIndex' && message.success(t('IndexUpdateProductsMessageSucces'))
	}

	const messageError = (value) => {
		value === 'updateCategoryIndex' ?
			message.error(t('IndexUpdateCategoryMessageError')) :
			value === 'updateProductsIndex' && message.error(t('IndexUpdateProductsMessageError'))

	}

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMoveImmutable(
				props.category.slice(),
				oldIndex,
				newIndex
			).filter((el) => !!el)
			props.updateIndex(newData, messageSucces, messageError)
		}
	}

	const DraggableContainer = (props) => (
		<SortableBody
			useDragHandle
			disableAutoscroll
			helperClass='row-dragging'
			onSortEnd={onSortEnd}
			{...props}
		/>
	)
	const DraggableBodyRow = ({ className, style, ...restProps }) => {
		const index = props.category.findIndex(
			(x) => x.index === restProps['data-row-key']
		)
		return <SortableItem index={index} {...restProps} />
	}

	const columns = [
		{
			dataIndex: 'sort',
			key: 'sort',
			width: '78px',
			className: 'sort',
			render: () => <DragHandle style={{ width: '78px' }} />,
		},
		{
			title: `${t('listColumnsPhotoTitle')}`,
			dataIndex: 'photoUrl',
			key: 'photoUrl',
			width: '78px',
			className: 'header-center',
			render: (text, record) => {
				return (
					<div
						style={{ textAlign: 'center', cursor: 'pointer' }}
						className='typography_variant_body2'
						onClick={() => props.navigateToProducts(record.id)}
					>
						<img
							src={text}
							style={{ borderRadius: '50px' }}
							width='30px'
							height='30px'
						/>
					</div>
				)
			},
		},
		{
			title: `${t('listColumnsNameTitle')}`,
			dataIndex: 'name',
			key: 'name',
			width: '644px',
			className: 'name',
		},
		{
			title: `${t('listColumnsStatusTitle')}`,
			dataIndex: 'status',
			key: 'status',
			width: '289px',
			render: (text) => {
				return text === 'active' ? (
					<span>
						<img src='/assets/OtherIcons/circle_active.svg' />
						{t('listColumnsStatusActive')}
					</span>
				) : (
					<span>
						<img src='/assets/OtherIcons/circle_not_active.svg' />
						{t('listColumnsStatusNotActive')}
					</span>
				)
			},
		},
		{
			title: `${t('listColumnsActionTitle')}`,
			dataIndex: 'action',
			key: 'action',
			width: '95px',
			className: 'action',
			render: (text, record) => (
				<span
					className='typography_variant_body2'
					style={{ cursor: 'pointer' }}
					onClick={(e) => {
						e.stopPropagation()
						props.editCategory(record.id)
					}}
				>
					<img src='/assets/OtherIcons/edit_blue.svg' height='20px' />
				</span>
			),
		},
	]

	return (
		<>
			<Table
				pagination={false}
				columns={columns}
				dataSource={props.category}
				rowKey='index'
				components={{
					body: {
						wrapper: DraggableContainer,
						row: DraggableBodyRow,
					},
				}}
			/>
		</>
	)
}

export default CategoryList
