import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Affix, Button, Col, Layout, Row, Menu, Dropdown, Space } from 'antd'
import { DownOutlined, DribbbleOutlined } from '@ant-design/icons'
import { getLocal } from '../../store/actions/local'
import { logOut } from '../../store/actions/login'
import enUS from 'antd/lib/locale/en_US'
import ukUA from 'antd/lib/locale/uk_UA'
import ruRU from 'antd/lib/locale/ru_RU'

const { Header } = Layout

const HeaderMain = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { t, i18n } = useTranslation()

	const { logoUrl } = useSelector((state) => state.login)
	// const { isAuth } = useSelector((state) => state.login)

	const changeLanguage = (language) => {
		i18n.changeLanguage(language)
		if (language === 'en') {
			dispatch(getLocal(enUS))
		} else if (language === 'ua') {
			dispatch(getLocal(ukUA))
		} else if (language === 'ru') {
			dispatch(getLocal(ruRU))
		}
	}
	// useEffect(() => {
	// 	if (!isAuth) {
	const logOutRoute = () => {
		navigate(`login`)
	}
	// }, [isAuth])

	const menu = (
		<Menu
			items={[
				{
					label: <a onClick={() => changeLanguage('en')}>En</a>,
					key: '0',
				},
				{
					label: <a onClick={() => changeLanguage('ua')}>Ua</a>,
					key: '1',
				},
				{
					label: <a onClick={() => changeLanguage('ru')}>Ru</a>,
					key: '2',
				},
			]}
		/>
	)
	return (
		<Header
			className='header'
			style={{
				position: 'fixed',
				zIndex: '4',
				width: '100%',
				flex: 'space-between',
				boxShadow: '0px 2px 8px #F0F1F2',
			}}
		>
			<Row justify='space-between'>
				<Col>
					<img style={{ height: '64px', width: '80px' }} src={logoUrl} />
				</Col>
				<Col>
					<Dropdown overlay={menu} trigger={['click']}>
						<a onClick={(e) => e.preventDefault()}>
							<Space size={5}>
								<DribbbleOutlined style={{ color: '#08c' }} />
								<DownOutlined style={{ fontSize: '8px' }} />
							</Space>
						</a>
					</Dropdown>
					<Button
						style={{
							border: 'none',
							color: '#FFC107',
						}}
						onClick={() => {
							dispatch(logOut())
							logOutRoute()
						}}
					>
						<span>
							{t('exit')} <img src='/assets/OtherIcons/logOut.svg' />
						</span>
					</Button>
				</Col>
			</Row>
		</Header>
	)
}

export default HeaderMain
