import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './CategoryUpdate.css'
import {
	Button,
	Col,
	Input,
	Layout,
	PageHeader,
	Row,
	Select,
	Typography,
	message,
} from 'antd'
import Photo from '../../UI/Photo/Photo'
import { useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
	deleteCategory,
	getCategoryDetails,
	updateCategory,
} from '../../../store/actions/category'
import ModalDelete from '../../UI/Modal/ModalDelete'
import {Content} from "antd/es/layout/layout";
const { Text } = Typography
const { Option } = Select

const CategoryUpdate = () => {
	const { t } = useTranslation()
	const params = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { categoryDetails, isChangedCategory, loadingCategory } = useSelector(
		(state) => state.category
	)
	const [image, setImage] = useState('')
	const [name, setName] = useState('')
	const [status, setStatus] = useState('')
	const routes = [
		{ path: 'category', breadcrumbName: `${t('category')}` },
		{ path: 'add', breadcrumbName: `${name}` },
	]

	useEffect(() => {
		setImage(categoryDetails.photoUrl)
		setName(categoryDetails.name)
		setStatus(categoryDetails.status)
	}, [categoryDetails])

	useEffect(() => {
		dispatch(getCategoryDetails(params.id))
	}, [])

	useEffect(() => {
		if (isChangedCategory) {
			navigate('/category')
		}
	}, [isChangedCategory])

	const messageSucces = (value) => {
		value === 'update'
			? message.success(t('UpdateCategoryMessageSucces'))
			: value === 'del'
			? message.success(t('DelCategoryMessageSucces'))
			: message.success(t('CreateCategoryMessageSucces'))
	}

	const messageError = (value) => {
		value === 'update'
			? message.error(t('UpdateCategoryMessageError'))
			: value === 'del'
			? message.success(t('DelCategoryMessageError'))
			: message.success(t('CreateCategoryMessageError'))
	}

	const changeItem = () => {
		if (name.length > 0 && image) {
			let categoryData = {
				name: name,
				status: status,
				photoUrl: image,
				originalImagename: categoryDetails?.originalImagename,
			}
			dispatch(
				updateCategory(params.id, categoryData, messageError, messageSucces)
			)
		} else {
			//error
		}
	}

	const deleteCategoryAndProducts = () => {
		dispatch(
			deleteCategory(
				categoryDetails?.originalImagename,
				params.id,
				messageError,
				messageSucces
			)
		)
	}

	return (
		<>
			<PageHeader
				title={t('category')}
				breadcrumb={{ routes }}
			></PageHeader>
			<Content style={{
				backgroundColor: "white",
				margin: 24
			}}>
				<Row style={{ position: 'absolute', right: '20px', top: '20px' }}>
					<ModalDelete
						deleteFunction={deleteCategoryAndProducts}
						loadingCategory={loadingCategory}
					/>
				</Row>
				<Row>
					<Col
						span={8}
						offset={8}
						style={{ padding: '24px 0 24px 0', width: '320px' }}
					>
						<Photo
							url={categoryDetails ? categoryDetails?.photoUrl : ''}
							onChange={(e) => setImage(e)}
							updateCategory={true}
						/>
						<Text>{t('labelForInputName')}</Text>
						<Input
							value={name}
							style={{ margin: '10px 0 15px 0' }}
							placeholder='Введіть назву'
							onChange={(e) => setName(e.target.value)}
						/>
						<Text>{t('labelForInputStatus')}</Text>
						<Select
							style={{ margin: '10px 0 40px 0', width: '100%' }}
							defaultActiveFirstOption='true'
							value={status}
							onChange={(e) => setStatus(e)}
						>
							<Option id={1} value='active'>
								<img src='/assets/OtherIcons/circle_active.svg' />
								{t('listColumnsStatusActive')}
							</Option>
							<Option id={2} value='not_active'>
								<img src='/assets/OtherIcons/circle_not_active.svg' />
								{t('listColumnsStatusNotActive')}
							</Option>
						</Select>
						<Button
							type='primary'
							loading={loadingCategory}
							onClick={() => changeItem()}
							style={{ marginRight: '10px' }}
						>
							{t('acceptChangeCategory')}
						</Button>
						<Button onClick={() => navigate('/category')}>
							{t('cancelChangeCategory')}
						</Button>
					</Col>
				</Row></Content>

		</>
	)
}

export default CategoryUpdate
