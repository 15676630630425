import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Menu } from 'antd'
import SideBarIcon from '../UI/Icons/SideBarIcon'

function getItem(label, key, icon, children, type) {
	return { key, icon, children, label, type }
}

const Sider = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const loc = location.pathname.includes('category')
		? 'category'
		: location.pathname.slice(1)

	const [activeTab, setActiveTab] = useState(
		location.pathname === '/' ? '/' : loc
	)

	const { t } = useTranslation()

	const navigateTo = (item) => {
		if (item.key === '/') {
			navigate(`${item.key}`)
			setActiveTab('/')
		} else {
			navigate(`/${item.key}`)
			setActiveTab(item.key)
		}
	}

	// const saveImage = (url) => {
	// 	let img = document.createElement('img')
	// 	img.src = url
	// 	img.onload = function () {
	// 		let key = encodeURIComponent(url),
	// 			canvas = document.createElement('canvas')

	// 		canvas.width = img.width
	// 		canvas.height = img.height
	// 		let ctx = canvas.getContext('2d')
	// 		ctx.drawImage(img, 0, 0)
	// 		localStorage.setItem(key, canvas.toDataURL('image/svg'))
	// 	}
	// }

	const items = [
		getItem(
			`${t('main')}`,
			'/',
			<SideBarIcon name={activeTab !== '/' ? 'actions' : 'actionsActive'} />
		),
		getItem(
			`${t('category')}`,
			'category',
			<SideBarIcon
				// name={activeTab !== 'category' ? 'category' : 'categoryActive'}
				name={activeTab.includes('category') ? 'categoryActive' : 'category'}
			/>
		),
		getItem(
			`${t('order')}`,
			'orders',
			<SideBarIcon name={activeTab !== 'orders' ? 'orders' : 'ordersActive'} />
		),
		getItem(
			`${t('users')}`,
			'users',
			<SideBarIcon name={activeTab !== 'users' ? 'users' : 'usersActive'} />
		),
		getItem(
			`${t('partners')}`,
			'partners',
			<SideBarIcon
				name={activeTab !== 'partners' ? 'partners' : 'partnersActive'}
			/>
		),
		getItem(
			`${t('promotions')}`,
			'actions',
			<SideBarIcon
				name={activeTab !== 'actions' ? 'actions' : 'actionsActive'}
			/>
		),
		getItem(
			`${t('pushnotification')}`,
			'pushnotification',
			<SideBarIcon
				name={
					activeTab !== 'pushnotification'
						? 'pushnotification'
						: 'pushnotificationActive'
				}
			/>
		),
		getItem(
			`${t('ingredients')}`,
			'ingredients',
			<SideBarIcon
				name={activeTab !== 'ingredients' ? 'ingredients' : 'ingredientsActive'}
			/>
		),
		getItem(
			`${t('reviews')}`,
			'review',
			<SideBarIcon
				name={activeTab !== 'review' ? 'reviews' : 'reviewsActive'}
			/>
		),
		getItem(`${t('finance')}`, 'sub1', <SideBarIcon name='finance' />, [
			getItem(`${t('income')}`, 'income'),
			getItem(`${t('outlay')}`, 'outlay'),
		]),
		getItem(`${t('personnel')}`, 'sub2', <SideBarIcon name='actions' />, [
			getItem(`${t('cashiers')}`, 'cashiers'),
			getItem(`${t('couriers')}`, 'couriers'),
		]),
		getItem(`${t('settings')}`, 'sub3', <SideBarIcon name='actions' />, [
			getItem(`${t('locations')}`, 'locations'),
			getItem(`${t('delivery')}`, 'delivery'),
			getItem(`${t('requisites')}`, 'requisites'),
			getItem(`${t('payments')}`, 'payments'),
			getItem(`${t('contacts')}`, 'contacts'),
			getItem(`${t('hall')}`, 'hall'),
		]),
		getItem(
			`${t('how to use')}`,
			'instructions',
			<SideBarIcon name='actions' />
		),
	]

	// const uploadIconToLocalStorage = () => {
	// 	items.map((item) =>
	// 		saveImage(`assets/NavigationIcons/${item.icon.props.name}.svg`)
	// 	)
	// }
	// uploadIconToLocalStorage()

	return (
		<Menu
			style={{
				borderRight: 'none',
			}}
			onClick={(e) => navigateTo(e)}
			defaultSelectedKeys={[activeTab]}
			mode='inline'
			theme='light'
			items={items}
		/>
	)
}
export default Sider
