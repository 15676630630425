import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { PageHeader, Row, Col, Input, Button, Radio } from 'antd'
import {
	filterCategory,
	getAllCategory,
	getCategory,
	isChangedCategory,
	UpdateIndexCategory,
} from '../../../store/actions/category'
import CategoryCards from './CategoryCards/CategoryCards'
import CategoryList from './CategoryList/CategoryList'
import EmptyState from '../../UI/EmptyState/EmptyState'
import EmptyStateSearch from '../../UI/EmptyStateSearch/EmptyStateSearch'
import Skeleton from 'react-loading-skeleton'
import { Content } from 'antd/es/layout/layout'

const { Search } = Input
const Category = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { filteredCategory, loadingCategory, category } = useSelector(
		(state) => state.category
	)
	const [toggleViewCategory, setToggleViewCategory] = useState('a')

	useEffect(() => {
		dispatch(getAllCategory())
		dispatch(isChangedCategory(false))
		console.log('renderCategoryUseEffect')
	}, [])

	console.log('renderCategory')

	const routes = [{ path: 'category', breadcrumbName: `${t('category')}` }]

	const addNewCategory = () => {
		navigate('/category/add')
	}

	const editCategory = (id) => {
		navigate(`/category/edit/${id}`)
	}

	const navigateToProducts = (id) => {
		navigate(`/category/${id}`)
	}

	const updateIndexCategory = (newData, messageSucces, messageError) => {
		dispatch(UpdateIndexCategory(newData, messageSucces, messageError))
		dispatch(getCategory(newData))
	}

	const skeletonSize = [1, 2, 3, 4, 5, 6, 7, 8]

	return (
		<>
			<PageHeader
				title={`${t('category')}`}
				breadcrumb={{ routes }}
				extra={[
					filteredCategory.length ? (
						<Button type='primary' onClick={() => addNewCategory()}>
							+ {t('headerAddButton')}
						</Button>
					) : null,
				]}
			>
				<Row>
					<Col
						span={24}
						style={{ display: 'flex', justifyContent: 'space-between' }}
					>
						<Col
							xxl={{ span: 8, offset: 8 }}
							xl={{ span: 8, offset: 8 }}
							lg={{ span: 10, offset: 6 }}
							md={{ span: 14 }}
							sm={{ span: 16 }}
							xs={{ span: 20 }}
						>
							<Search
								placeholder={t('searchPlaceholder')}
								allowClear
								enterButton={t('searchEnterButton')}
								size='medium'
								onChange={(e) => dispatch(filterCategory(e.target.value))}
							/>
						</Col>
						{filteredCategory.length && category.length ? (
							<Radio.Group
								onChange={(value) => setToggleViewCategory(value.target.value)}
								defaultValue={toggleViewCategory}
								buttonStyle='solid'
							>
								<Radio.Button value='a'>
									<img
										src={`/assets/OtherIcons/grid_${
											toggleViewCategory === 'b' ? 'yellow' : 'white'
										}.svg`}
									/>
								</Radio.Button>
								<Radio.Button value='b'>
									<img
										src={`/assets/OtherIcons/list_${
											toggleViewCategory === 'a' ? 'yellow' : 'white'
										}.svg`}
									/>
								</Radio.Button>
							</Radio.Group>
						) : null}
					</Col>
				</Row>
			</PageHeader>
			<Content
				style={{
					margin: 24,
				}}
			>
				{!loadingCategory ? (
					toggleViewCategory === 'a' ? (
						filteredCategory.length ? (
							<CategoryCards
								navigateToProducts={navigateToProducts}
								editCategory={editCategory}
								category={filteredCategory}
							/>
						) : category.length ? (
							<EmptyStateSearch />
						) : (
							<EmptyState addItem={addNewCategory} />
						)
					) : filteredCategory.length ? (
						<CategoryList
							navigateToProducts={navigateToProducts}
							editCategory={editCategory}
							updateIndex={updateIndexCategory}
							category={filteredCategory}
						/>
					) : category.length ? (
						<EmptyStateSearch />
					) : (
						<EmptyState addItem={addNewCategory} />
					)
				) : (
					<Row gutter={[16, 16]} style={{ padding: '22px 24px' }}>
						{' '}
						{skeletonSize.map((value) => {
							return (
								<Col key={value} xx l={4} xl={6} lg={8} md={12} sm={12} xs={24}>
									<Skeleton key={value} inline height={203} count={1} />
								</Col>
							)
						})}
					</Row>
				)}
			</Content>
		</>
	)
}

export default Category
