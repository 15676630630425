import React from 'react';
import {Button, Col, Row, Typography} from "antd";
const {Text} = Typography

const EmptyState = (props) => {

    return (
        <>
            <Row justify={'center'} align={'middle'} style={{height: 'calc(100% - 142px)'}}>
                <Col>
                    <Row style={{paddingBottom: '8px'}}>
                        <img src='/assets/img/empty-image.png'/></Row>
                    <Row justify={'center'} style={{paddingBottom: '16px'}}>
                        <Text>Немає данних</Text>
                    </Row>
                    <Row justify={'center'}>
                        { props.addItem ? <Button type='primary' onClick={() => props.addItem()}>+ Додати</Button> : null}
                    </Row>
                </Col>
            </Row>
        </>

    );
};

export default EmptyState;