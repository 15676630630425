import { GET_LOCAL, LOADING_LOCAL } from '../actionTypes/local'
import ukUA from 'antd/lib/locale/uk_UA'

const InitialState = {
	local: ukUA,
	loadingLocal: false,
}

export const localReducer = (state = InitialState, action) => {
	switch (action.type) {
		case LOADING_LOCAL: {
			return { ...state, loadingLocal: action.payload }
		}
		case GET_LOCAL: {
			return {
				...state,
				local: action.payload,
			}
		}
		default:
			return state
	}
}
