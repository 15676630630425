import { GET_LOCAL, LOADING_LOCAL } from '../actionTypes/local'

//actions
export const loaderLocal = (boolean) => {
	return {
		type: LOADING_LOCAL,
		payload: boolean,
	}
}

export const getLocal = (local) => {
	return {
		type: GET_LOCAL,
		payload: local,
	}
}
