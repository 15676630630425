import React from 'react'
import { Button, Card, Col, Row, Typography } from 'antd'
const { Title } = Typography

const CategoryCards = (props) => {
	return (
		<>
			<Row gutter={[16, 24]}>
				{props.category.map((category) => {
					return (
						<Col key={category} xxl={4} xl={6} lg={8} md={12} sm={12} xs={24}>
							<Card
								onClick={() => {
									props.navigateToProducts(category.id)
								}}
								hoverable
								cover={
									<img
										alt='example'
										src={category.photoUrl}
										style={{
											objectFit: 'cover',
											width: '100%',
											height: '150px',
										}}
									/>
								}
							>
								<Row justify={'space-between'} align={'middle'}>
									<Col>
										<Row align={'middle'}>
											<img
												src={`/assets/OtherIcons/circle_${category.status}.svg`}
												style={{
													marginRight: '8px',
												}}
											/>
											<Title level={5}>{category.name}</Title>
										</Row>
									</Col>
									<Button
										type='primary'
										onClick={(e) => {
											e.stopPropagation()
											props.editCategory(category.id)
										}}
										icon={<img src='/assets/OtherIcons/edit_yellow.svg' />}
									/>
								</Row>
							</Card>
						</Col>
					)
				})}
			</Row>
		</>
	)
}

export default CategoryCards
