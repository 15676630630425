import React, { useEffect, useState } from 'react'
import {
	Alert,
	Button,
	Checkbox,
	Col,
	Form,
	Input,
	Layout,
	Row,
	Typography,
	Spin,
} from 'antd'
import { useLocation, Navigate, useNavigate } from 'react-router'
import { getLogoUrl, loginUser } from '../../store/actions/login'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { storage } from '../../firebase'
import { getDownloadURL, ref } from 'firebase/storage'

const LoginPage = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { loading, isAuth, error, user, logoUrl } = useSelector(
		(state) => state.login
	)
	const { Title } = Typography

	useEffect(() => {
		const logo = ref(storage, 'logo/logo.png')
		getDownloadURL(logo).then((url) => {
			dispatch(getLogoUrl(url))
		})
	}, [])

	const location = useLocation()
	// console.log(location.state?.from?.pathname)
	const page = location.state?.from?.pathname || '/'
	// console.log(location.pathname)

	const onSubmit = (data) => {
		dispatch(loginUser(data))
	}
	// console.log(user)
	// useEffect(() => {
	// 	if (user) {
	// 		// <Navigate to={page} state={{ from: location }} replace />

	// 		return <Navigate to={'/category'} state={{ from: location }} replace />
	// 		// navigate(`${page}`, { state: { from: location } })
	// 	} else if (user === null && loading) {
	// 		return <Spin className='example' size='large' />
	// 	}
	// }, [user])
	return user ? (
		// navigate(`${page}`, { state: { from: location } })
		<Navigate to={`${page}`} state={{ from: location }} replace />
	) : user === null && loading ? (
		<Spin className='example' size='large' />
	) : (
		<Layout style={{ height: '100vh', justifyContent: 'center' }}>
			<Row align={'middle'} style={{ height: '100%' }}>
				<Col
					xxl={{ span: 8, offset: 8 }}
					xl={{ span: 10, offset: 7 }}
					lg={{ span: 14, offset: 5 }}
					md={{ span: 16, offset: 4 }}
					sm={{ span: 20, offset: 2 }}
					xs={{ span: 22, offset: 1 }}
					style={{
						backgroundColor: 'white',
						borderRadius: '8px',
					}}
				>
					<Row justify='center'>
						<img
							src='/assets/img/login-img.png'
							style={{
								width: '200px',
								height: '200px',
								marginTop: '30px',
							}}
							alt=''
						/>
					</Row>
					<Row justify={'center'}>
						<Col xxl={12} xl={14} lg={14} md={16} sm={16} xs={20}>
							<Title
								level={3}
								style={{
									marginBottom: '30px',
								}}
							>
								{t('mainTitle')}
							</Title>
							<Form
								colon={true}
								name='basic'
								initialValues={{ remember: true }}
								onFinish={onSubmit}
								autoComplete='off'
								layout='vertical'
							>
								<Form.Item
									label={t('labelForMail')}
									name='email'
									className='inputEmail'
								>
									<Input />
								</Form.Item>
								<Form.Item
									style={{ marginBottom: '10px' }}
									label={t('labelForPassword')}
									name='password'
									className='inputPassword'
								>
									<Input.Password />
								</Form.Item>
								{error && (
									<Alert className='alert-login' message={error} type='error' />
								)}
								<Col
									style={{
										textAlign: 'end',
									}}
								>
									<Form.Item name='remember' valuePropName='checked'>
										<Checkbox>{t('rememberMe')}</Checkbox>
									</Form.Item>
								</Col>
								<Button
									style={{ width: '100%', marginBottom: '70px' }}
									type='primary'
									loading={loading}
									className='submit__btn'
									htmlType='submit'
								>
									{t('inputButton')}
								</Button>
							</Form>
						</Col>
					</Row>
				</Col>
			</Row>
		</Layout>
	)
}

export default LoginPage
