import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getAllUsers } from '../../../store/actions/users'
import { Table, Space, Row, Col, Tag } from 'antd'
import moment from 'moment'

const OrderList = (props) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const { users } = useSelector((state) => state.users)

	const [filteredInfo, setFilteredInfo] = useState({})

	const handleChange = (pagination, filters, sorter) => {
		setFilteredInfo(filters)
	}

	useEffect(() => {
		dispatch(getAllUsers())
	}, [])

	const columns = [
		{
			title: '№',
			dataIndex: 'orderNumber',
			key: 'orderNumber',
			width: '50px',

			// defaultSortOrder: 'descend',
			// sorter: (a, b) => a.orderNumber - b.orderNumber,
		},
		{
			title: `${t('orderColumnsDateTitle')}`,
			dataIndex: 'updateAt',
			key: 'updateAt',
			width: '200px',
			render: (price, record) => {
				const timeStamp =
					`${record.updateAt.seconds}${record.updateAt.nanoseconds}`.slice(
						0,
						13
					)
				return timeStamp.length < 13
					? moment(Number(timeStamp + '00')).format(`DD.MM.YYYY HH:mm`)
					: moment(Number(timeStamp)).format(`DD.MM.YYYY HH:mm`)
			},
		},
		{
			title: `${t('listColumnsStatusTitle')}`,
			dataIndex: 'status',
			key: 'status',
			width: '200px',
			render: (text) => {
				return text === 'cooking' ? (
					<Space size={[8]}>
						<img src='/assets/OtherIcons/indicator_state_in_progress.svg' />
						{t('orderColumnsStatusCooking')}
					</Space>
				) : text === 'transfer_money' ? (
					<Space size={[8]}>
						<img src='/assets/OtherIcons/indicator_state_in_progress.svg' />
						{t('orderColumnsStatusTransferMoney')}
					</Space>
				) : text === 'checking_payment' ? (
					<Space size={[8]}>
						<img src='/assets/OtherIcons/indicator_state_in_progress.svg' />
						{t('orderColumnsStatusCheckingPayment')}
					</Space>
				) : text === 'accepting' ? (
					<Space size={[8]}>
						<img src='/assets/OtherIcons/indicator_state_in_progress.svg' />
						{t('orderColumnsStatusAccepting')}
					</Space>
				) : text === 'delivery' ? (
					<Space size={[8]}>
						<img src='/assets/OtherIcons/indicator_state_in_progress.svg' />
						{t('orderColumnsStatusDeliver')}
					</Space>
				) : text === 'take_from_courier' ? (
					<Space size={[8]}>
						<img src='/assets/OtherIcons/indicator_state_in_progress.svg' />
						{t('orderColumnsStatusTakeFromCourier')}
					</Space>
				) : text === 'delivered' ? (
					<Space size={[8]}>
						<img src='/assets/OtherIcons/indicator_state_completed.svg' />
						{t('orderColumnsStatusDelivered')}
					</Space>
				) : text === 'picked_up' ? (
					<Space size={[8]}>
						<img src='/assets/OtherIcons/indicator_state_completed.svg' />
						{t('orderColumnsStatusPickedUp')}
					</Space>
				) : text === 'taken_out' ? (
					<Space size={[8]}>
						<img src='/assets/OtherIcons/indicator_state_completed.svg' />
						{t('orderColumnsStatusTakenOut')}
					</Space>
				) : text === 'payment_not_confirmed' ? (
					<Space size={[8]}>
						<img src='/assets/OtherIcons/indicator_state_canceled.svg' />
						{t('orderColumnsStatusPaymentNotConfirmed')}
					</Space>
				) : (
					<Space size={[8]}>
						<img src='/assets/OtherIcons/indicator_state_canceled.svg' />
						{t('orderColumnsStatusCanceled')}
					</Space>
				)
			},
			filters: [
				{
					text: `${t('orderColumnsStatusCooking')}`,
					value: 'cooking',
				},
				{
					text: `${t('orderColumnsStatusTransferMoney')}`,
					value: 'transfer_money',
				},
				{
					text: `${t('orderColumnsStatusCheckingPayment')}`,
					value: 'checking_payment',
				},
				{
					text: `${t('orderColumnsStatusAccepting')}`,
					value: 'accepting',
				},
				{
					text: `${t('orderColumnsStatusDeliver')}`,
					value: 'delivery',
				},
				{
					text: `${t('orderColumnsStatusTakeFromCourier')}`,
					value: 'take_from_courier',
				},
				{
					text: `${t('orderColumnsStatusDelivered')}`,
					value: 'delivered',
				},
				{
					text: `${t('orderColumnsStatusPickedUp')}`,
					value: 'picked_up',
				},
				{
					text: `${t('orderColumnsStatusTakenOut')}`,
					value: 'taken_out',
				},
				{
					text: `${t('orderColumnsStatusPaymentNotConfirmed')}`,
					value: 'payment_not_confirmed',
				},
				{
					text: `${t('orderColumnsStatusCanceled')}`,
					value: 'canceled',
				},
			],
			filteredValue: filteredInfo.status || null,
			onFilter: (value, record) => record.status.includes(value),
		},

		{
			title: `${t('orderColumnsNameTitle')}`,
			dataIndex: 'name',
			key: 'name',
			width: '250px',
			render: (text, record) => {
				return users.map((user) => {
					if (user.id === record.userId) {
						return (
							<span>
								{user.firstName} {user.secondName}
							</span>
						)
					}
				})
			},
		},
		{
			title: `${t('orderColumnsTypeOrderTitle')}`,
			dataIndex: 'deliveryType',
			key: 'deliveryType',
			width: '200px',
			render: (text) => {
				return text === 'delivery' ? (
					<Space size={[8]}>
						<Tag color='magenta'>{t('orderColumnsTypeOrderDelivery')}</Tag>
					</Space>
				) : text === 'establishment' ? (
					<Space size={[8]}>
						<Tag color='green'>{t('orderColumnsTypeOrderEstablishment')}</Tag>
					</Space>
				) : text === 'pickup' ? (
					<Space size={[8]}>
						<Tag color='cyan'>{t('orderColumnsTypeOrderPickUp')}</Tag>
					</Space>
				) : (
					<Space size={[8]}>
						<Tag color='purple'>{t('orderColumnsTypeOrderPartners')}</Tag>
					</Space>
				)
			},
			filters: [
				{
					text: `${t('orderColumnsTypeOrderPartners')}`,
					value: 'partner',
				},
				{
					text: `${t('orderColumnsTypeOrderPickUp')}`,
					value: 'pickup',
				},
				{
					text: `${t('orderColumnsTypeOrderEstablishment')}`,
					value: 'establishment',
				},
				{
					text: `${t('orderColumnsTypeOrderDelivery')}`,
					value: 'delivery',
				},
			],
			filteredValue: filteredInfo.deliveryType || null,
			onFilter: (value, record) => record.deliveryType.includes(value),
		},
		{
			title: `${t('orderColumnsTypePayTitle')}`,
			dataIndex: 'paymentType',
			key: 'paymentType',
			width: '160px',
			render: (text) => {
				return text === 'cash' ? (
					<Space size={[8]}>{t('orderColumnsPaymentTypeCash')}</Space>
				) : (
					<Space size={[8]}>{t('orderColumnsPaymentTypeCard')}</Space>
				)
			},
			filters: [
				{
					text: `${t('orderColumnsPaymentTypeCash')}`,
					value: 'cash',
				},
				{
					text: `${t('orderColumnsPaymentTypeCard')}`,
					value: 'card',
				},
			],
			filteredValue: filteredInfo.paymentType || null,
			onFilter: (value, record) => record.paymentType.includes(value),
		},
		{
			title: `${t('orderColumnsCashTitle')}`,
			dataIndex: 'total',
			key: 'total',
			width: '90px',
			render: (price, record) => {
				return (
					<Row justify='space-between'>
						<Col>{record.price.total}</Col>
						<Col>{t('orderCurrency')}</Col>
					</Row>
				)
			},
		},
	]

	return (
		<>
			<Table
				onChange={handleChange}
				pagination={false}
				columns={columns}
				dataSource={props.order}
				rowKey='index'
				scroll={{
					y: 480,
				}}
			/>
		</>
	)
}

export default OrderList
