import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.less'
import LoginPage from './pages/LoginPage/LoginPage'
import Dashboard from './components/DashboardSection/Dashboard'
import Category from './components/CategorySection/Category/Category'
import CategoryAdd from './components/CategorySection/CategoryAdd/CategoryAdd'
import CategoryUpdate from './components/CategorySection/CategoryUpdate/CategoryUpdate'
import ProductAdd from './components/ProductSection/ProductAdd/ProductAdd'
import Product from './components/ProductSection/Product/Product'
import ProductUpdate from './components/ProductSection/ProductUpdate/ProductUpdate'
import Orders from './components/OrdersSection/Orders/Orders'
import OrderAdd from './components/OrdersSection/Orders/OrderAdd'
import OrderDetails from './components/OrdersSection/OrdersDetails/OrderDetails'
import Actions from './components/Actions/Actions'
import PushNotification from './components/PushNotification/PushNotification'
import Ingredients from './components/IngredientsSection/Ingredients'
import Partners from './components/PartnersSection/Partners'
import Users from './components/UsersSection/Users/Users'
import Review from './components/ReviewSection/Review'
import Location from './components/LocationSection/Location/Location'
import LocationAdd from './components/LocationSection/LocationAdd/LocationAdd'
import LocationUpdate from './components/LocationSection/LocationUpdate/LocationUpdate'
import Delivery from './components/DeliverySection/Delivery/Delivery'
import DeliveryUpdate from './components/DeliverySection/DeliveryUpdate/DeliveryUpdate'
import Requisites from './components/RequisitesSection/Requisites/Requisites'
import RequisitesAdd from './components/RequisitesSection/RequisitesAdd/RequisitesAdd'
import RequisitesUpdate from './components/RequisitesSection/RequisitesUpdate/RequisitesUpdate'
import Payment from './components/PaymentSection/Payment'
import Contacts from './components/ContactsSection/Contacts/Contacts'
import ContactsAdd from './components/ContactsSection/ContactsAdd/ContactsAdd'
import ContactsUpdate from './components/ContactsSection/ContactsUpdate/ContactsUpdate'
import Hall from './components/HallSection/Hall/Hall'
import HallAdd from './components/HallSection/HallAdd/HallAdd'
import HallUpdate from './components/HallSection/HallUpdate/HallUpdate'
import Instruction from './components/InstructionSection/Instruction'
import ContentPage from './pages/ContentPage/ContentPage'
import { useDispatch, useSelector } from 'react-redux'
import { loadUser } from './store/actions/login'

function App() {
	const dispatch = useDispatch()
	const { logoUrl, user } = useSelector((state) => state.login)

	let favicon = document.getElementById('favicon')
	favicon.href = logoUrl

	useEffect(() => {
		dispatch(loadUser())
		console.log('render')
	}, [user])

	return (
		<BrowserRouter>
			<Routes>
				<Route path='login' element={<LoginPage />} />
				<Route path='*' element={<LoginPage />} />
				<Route element={<ContentPage />}>
					<Route path='/' element={<Dashboard />} />
					<Route path='category' element={<Category />} />
					<Route path='category/add' element={<CategoryAdd />} />
					<Route path='category/edit/:id' element={<CategoryUpdate />} />
					<Route path='category/:id' element={<Product />} />
					<Route path='category/:id/product/add' element={<ProductAdd />} />
					<Route path='category/:id/product/edit' element={<ProductUpdate />} />
					<Route path='orders' element={<Orders />} />
					<Route path='orders/:id' element={<OrderDetails />} />
					<Route path='orders/add' element={<OrderAdd />} />
					<Route path='users' element={<Users />} />
					{/*<Route path="user/:id" element={<UserDetails />} />*/}
					{/*<Route path="user/:id/orderhistory" element={<OrderHistory />}/>*/}
					{/*<Route path="user/:id/orderhistory/orderdetails" element={<UserorderDetails />}/>*/}
					<Route path='partners' element={<Partners />} />
					<Route path='actions' element={<Actions />} />
					<Route path='ingredients' element={<Ingredients />} />
					<Route path='pushnotification' element={<PushNotification />} />
					{/*<Route path="pushnotification/add" element={<AddNotication />}/>*/}
					{/*<Route path="pushnotification/edit/:id" element={<EditNotification />}/>*/}
					{/*<Route path="pushnotification/:id" element={<NotificationDetails />}/>*/}
					<Route path='review' element={<Review />} />
					{/*<Route path="income" element={<Income />} />*/}
					<Route path='location' element={<Location />} />
					<Route path='location/add' element={<LocationAdd />} />
					<Route path='location/:id' element={<LocationUpdate />} />
					<Route path='delivery' element={<Delivery />} />
					<Route path='delivery/:id' element={<DeliveryUpdate />} />
					<Route path='requisites' element={<Requisites />} />
					<Route path='requisites/add' element={<RequisitesAdd />} />
					<Route path='requisites/:id' element={<RequisitesUpdate />} />
					<Route path='payment' element={<Payment />} />
					<Route path='contacts' element={<Contacts />} />
					<Route path='contacts/add' element={<ContactsAdd />} />
					<Route path='contacts/:id' element={<ContactsUpdate />} />
					<Route path='hall' element={<Hall />} />
					<Route path='hall/add' element={<HallAdd />} />
					<Route path='hall/:id' element={<HallUpdate />} />
					<Route path='instruction' element={<Instruction />} />
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export default App
