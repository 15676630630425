//thunks
import { collection, getDocs, query } from 'firebase/firestore/lite'
import { db } from '../../firebase'
import { GET_USERS, LOADING_USERS, FILTER_USERS } from '../actionTypes/users'

export const getAllUsers = () => {
	return async (dispatch) => {
		dispatch(loaderUsers(true))
		const q = query(collection(db, 'users'))
		const querySnapshot = await getDocs(q)
		if (querySnapshot) {
			const users = querySnapshot.docs.map((doc) => doc.data())
			dispatch(getUsers(users))
		}
	}
}

//actions
export const loaderUsers = (boolean) => {
	return {
		type: LOADING_USERS,
		payload: boolean,
	}
}

export const getUsers = (users) => {
	return {
		type: GET_USERS,
		payload: users,
	}
}

export const filterUsers = (searchText) => ({
	type: FILTER_USERS,
	payload: searchText,
})
