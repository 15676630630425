import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { loginReducer } from './reducers/loginReducer'
import { categoryReducer } from './reducers/category'
import { orderReducer } from './reducers/order'
import { localReducer } from './reducers/local'
import { productsReducer } from './reducers/products'
import { usersReducer } from './reducers/users'

const reducers = combineReducers({
	login: loginReducer,
	category: categoryReducer,
	order: orderReducer,
	local: localReducer,
	products: productsReducer,
	users: usersReducer,
})

const store = createStore(reducers, applyMiddleware(thunk))

export default store
