import { LOADING_USERS, GET_USERS, FILTER_USERS } from '../actionTypes/users'

const InitialState = {
	users: [],
	filteredUsers: [],
	loadingUsers: false,
}

export const usersReducer = (state = InitialState, action) => {
	switch (action.type) {
		case LOADING_USERS: {
			return { ...state, loadingUsers: action.payload }
		}
		case GET_USERS: {
			return { ...state, users: action.payload, filteredUsers: action.payload }
		}
		case FILTER_USERS: {
			return {
				...state,
				filteredUsers: state.users.filter((e) => {
					console.log(e)
					return e.usersNumber.includes(action.payload.toLowerCase())
				}),
			}
		}
		default:
			return state
	}
}
