import React, {useEffect, useState} from 'react';
import {Col, message, Modal, Row, Typography, Upload} from "antd";
import ImgCrop from "antd-img-crop";
import './Photo.css'
import {useTranslation} from "react-i18next";
const {Text} = Typography

const Photo = (props) => {

    const [visibleModal, setVisibleModal] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [imgUrl, setImgUrl] = useState('');
    const [isValid, setIsValid] = useState(false)
    const {t} = useTranslation()


    useEffect(() => {
        if (props.updateCategory) {
            setFileList([{
                uid: '-1',
                name: imgUrl || props.url,
                status: 'done',
                url: imgUrl || props.url
            }])
        } else {
            setFileList([])
        }
    }, [imgUrl, props.url])

    const uploadButton = (
        <Row className='uploadButton'align={'middle'} justify={'center'}>
            <Col>
                <Col span={24}><Text>+</Text></Col>
                <Col span={24}><Text >{t('uploadImage')}</Text></Col>
            </Col>
        </Row>
    );

    function beforeUpload(file) {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("Ви можете завантажувати лише файл JPG/PNG!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Розмір зображення має бути менше 2 МБ!");
        }
        setIsValid(isJpgOrPng && isLt2M);
        return isJpgOrPng && isLt2M;
    };

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };
    const cancelModal = () => {
        setVisibleModal(false)
    };

    const onChange = ({fileList: newFileList}) => {

        if (isValid) {
            setFileList(newFileList)
            props.onChange((newFileList[0].originFileObj))
        }
    };



    const dummyRequest = ({file, onSuccess}) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const onPreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setVisibleModal(true);
        setImgUrl(file.url === undefined ? file.preview : file.url);
    };
    return (
        <>
            <ImgCrop
                aspect={275 / 260.22}
                modalOk={t('acceptModalChange')}
                modalCancel={t('cancelModalChange')}
                modalTitle={t('titleModalChange')}
                beforeCrop={beforeUpload}
            >
                <Upload
                    name='avatar'
                    listType='picture-card'
                    className='avatar-uploader'
                    fileList={fileList}
                    showUploadList={true}
                    showRemoveIcon="false"
                    removeIcon="false"
                    maxCount={1}
                    customRequest={dummyRequest}
                    onChange={onChange}
                    onPreview={onPreview}
                >
                    <>
                        {fileList.length ? <p className="typography_variant_overline" style={{ color: "#146EB0" }}>{t('changePhotoCategory')}</p> : uploadButton}
                    </>
                </Upload>
            </ImgCrop>
            <Modal
                visible={visibleModal}
                footer={null}
                onCancel={cancelModal}
            >
                <img src={imgUrl} alt={'image'} style={{width: '100%'}}/>
            </Modal>
        </>
    );
};

export default Photo;