import {
    collection,
    doc,
    getDocs,
    query,
    getDoc,
    updateDoc,
    where,
    orderBy,
    deleteDoc,
    addDoc,
    setDoc
} from "firebase/firestore/lite";
import {db, storage} from "../../firebase";
import {
    FILTER_PRODUCTS,
    GET_PRODUCT_DETAILS,
    GET_PRODUCT_OPTIONS,
    GET_PRODUCTS,
    LOADING_PRODUCTS
} from "../actionTypes/products";
import {deleteObject, getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {Timestamp} from "@firebase/firestore";

export const getProductDetailsByCID = (id) => async (dispatch) => {
    try {
        dispatch(loaderProducts(true))
        const q = query(collection(db, "products"), where("categoryId", "==", id));
        const querySnapshot =  await getDocs(q);
        if (querySnapshot) {
            const products = querySnapshot.docs.map((doc) => doc.data())
            dispatch(loaderProducts(false))
            dispatch(getProducts(products))

        }
    } catch (error) {
        dispatch(loaderProducts(false))
    }
};

// Update Product index
export const UpdateIndexProduct = (data, messageSuccess, messageError) => async (dispatch) => {
    try {
        for (let v = 0; v < data.length; v++) {
            await updateDoc(doc(db, "products", data[v].id), {
                index: v + 1,
            });
            if (data.length === v + 1) {
                messageSuccess('updateProductsIndex')
            }
        }
    } catch (error) {
        messageError('updateProductsIndex')
    }
};

export const getProductDetails = (id) => async (dispatch) => {
    try {
        const q = query(
            collection(db, "products/" + id + "/options"),
            orderBy("index", "asc")
        );
        const optionsSnap = await getDocs(q);
        const docSnap = await getDoc(doc(db, "products", id));
        dispatch(productDetails(docSnap.data()))
        let productAllOptions = optionsSnap.docs.map((doc) => doc.data())
        dispatch(productOptions(productAllOptions))
    } catch (error) {

    }
};

// // Delete Product index
// export const DeleteIndexProduct = (id, optionId) => async (dispatch) => {
//     try {
//         dispatch({ type: DELETE_PRODUCT_INDEX_REQUEST });
//         await deleteDoc(doc(db, "products/" + id + "/options/", optionId));
//         dispatch({
//             type: DELETE_PRODUCT_INDEX_SUCCESS,
//             payload: true,
//         });
//     } catch (error) {
//         dispatch({ type: DELETE_PRODUCT_INDEX_FAIL, payload: true });
//     }
// };

// Delete Product
export const deleteProduct = (imageName, id, options) => async (dispatch) => {
    try {
        if (options) {
            if (options.length > 0) {
                for (let index = 0; index < options.length; index++) {
                    await deleteDoc(
                        doc(db, "products/" + id + "/options/", options[index]["optionId"])
                    );
                }
            }
        }

        const imageRef = ref(storage, "products/" + id + "/" + imageName);
        deleteObject(imageRef)
            .then(async () => {
                await deleteDoc(doc(db, "products", id));

            })
            .catch((error) => {

            });
    } catch (error) {

    }
};


// Create Product
export const createProduct = (productData) => async (dispatch) => {
    try {
        const categoryRef = collection(db, "categories");
        const docRef = await addDoc(collection(db, "products"), {
            createdAt: Timestamp.now(),
            categoryId: productData.categoryId,
            description: productData.description,
            index: productData.index,
            maxOptions: productData.maxOptions,
            name: productData.name,
            price: productData.price,
            status: productData.status,
            updatedAt: null,
        });

        if (docRef) {
            if (docRef.id) {
                const storageRef = ref(
                    storage,
                    `/products/${docRef.id}/product_image.${productData.photoUrl.type.split("/").pop()}`
                );
                const uploadTask = uploadBytesResumable(
                    storageRef,
                    productData.photoUrl
                );
                let originimgName = `product_image.${productData.photoUrl.type
                    .split("/")
                    .pop()}`;
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const prog = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                    },
                    (err) => console.log(err),
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                            if (url) {
                                if (productData.options) {
                                    if (productData.options.length > 0) {
                                        for (let i = 0; i < productData.options.length; i++) {
                                            let uniqueId = doc(categoryRef).id;
                                            await setDoc(
                                                doc(db, "products", docRef.id, "options", uniqueId),
                                                {
                                                    optionId: uniqueId,
                                                    index: i + 1,
                                                    name: productData.options[i]["name"],
                                                    price: productData.options[i]["price"],
                                                }
                                            );
                                        }
                                    }
                                }
                                const newCategoryRef = await updateDoc(
                                    doc(db, "products", docRef.id),
                                    {
                                        id: docRef.id,
                                        originalImagename: originimgName,
                                        photoUrl: url,
                                    }
                                );
                            }
                        });
                    }
                );
            }
        }
    } catch (error) {

    }};

// Update Product
export const updateProduct = (id, productData) => async (dispatch) => {
    try {
        const url = productData.photoUrl;
        let flag = 0;
        if (typeof productData.photoUrl == "object") {
            const deleteref = ref(
                storage,
                `/products/${id}/${productData.originalImagename}`
            );

            deleteObject(deleteref)
                .then(async () => {
                    const storageRef = ref(
                        storage,
                        `/products/${id}/product_image.${productData.photoUrl.type.split("/").pop()}`
                    );
                    let originimgName = `product_image.${productData.photoUrl.type.split("/").pop()}`;
                    const uploadTask = uploadBytesResumable(
                        storageRef,
                        productData.photoUrl
                    );
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => {
                            const prog = Math.round(
                                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                            );
                        },
                        (err) => console.log(err),
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                                if (url) {
                                    console.log(originimgName);
                                    await updateDoc(doc(db, "products", id), {
                                        updatedAt: Timestamp.now(),
                                        description: productData.description,
                                        maxOptions: productData.maxOptions,
                                        name: productData.name,
                                        photoUrl: url,
                                        price: productData.price,
                                        originalImagename: originimgName,
                                        status: productData.status,
                                    });
                                    // For the options
                                    if (productData.options) {
                                        if (productData.options.length > 0) {
                                            for (let i = 0; i < productData.options.length; i++) {
                                                if (productData.options[i]["optionId"] === "") {
                                                    const productRef = collection(db, "products");
                                                    const optId = doc(productRef).id;
                                                    await setDoc(
                                                        doc(db, "products", id, "options", optId),
                                                        {
                                                            optionId: optId,
                                                            index: productData.options[i]["index"],
                                                            name: productData.options[i]["name"],
                                                            price: productData.options[i]["price"],
                                                        }
                                                    );
                                                } else {
                                                    await setDoc(
                                                        doc(
                                                            db,
                                                            "products/" + id + "/options/",
                                                            productData.options[i]["optionId"]
                                                        ),
                                                        {
                                                            optionId: productData.options[i]["optionId"],
                                                            index: productData.options[i]["index"],
                                                            name: productData.options[i]["name"],
                                                            price: productData.options[i]["price"],
                                                        }
                                                    );
                                                }
                                            }
                                        }
                                    }

                                }
                            });
                        }
                    );
                })
                .catch((error) => {

                });
        } else {
            await updateDoc(doc(db, "products", id), {
                updatedAt: Timestamp.now(),
                description: productData.description,
                maxOptions: productData.maxOptions,
                name: productData.name,
                price: productData.price,
                status: productData.status,
            });
            // For the options
            if (productData.options) {
                if (productData.options.length > 0) {
                    for (let i = 0; i < productData.options.length; i++) {
                        if (productData.options[i]["optionId"] === "") {
                            const productRef = collection(db, "products");
                            console.log(doc(productRef).id);
                            const optId = doc(productRef).id;
                            await setDoc(doc(db, "products", id, "options", optId), {
                                optionId: optId,
                                index: productData.options[i]["index"],
                                name: productData.options[i]["name"],
                                price: productData.options[i]["price"],
                            });
                        } else {
                            await setDoc(
                                doc(
                                    db,
                                    "products/" + id + "/options/",
                                    productData.options[i]["optionId"]
                                ),
                                {
                                    optionId: productData.options[i]["optionId"],
                                    index: productData.options[i]["index"],
                                    name: productData.options[i]["name"],
                                    price: productData.options[i]["price"],
                                }
                            );
                        }
                    }
                }
            }

        }
    } catch (error) {
    }
};





export const loaderProducts = (boolean) => ({type: LOADING_PRODUCTS, payload: boolean});
export const getProducts = (products) => ({type: GET_PRODUCTS, payload: products})
export const filterProducts = (searchText) => ({type: FILTER_PRODUCTS, payload: searchText})
export const productDetails = (product) => ({type: GET_PRODUCT_DETAILS, payload: product});
export const productOptions = (options) => ({type: GET_PRODUCT_OPTIONS, payload: options})