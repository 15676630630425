import {
    LOADING_CATEGORY,
    GET_CATEGORY,
    FILTER_CATEGORY, GET_CATEGORY_DETAILS, IS_CHANGED_CATEGORY,
} from '../actionTypes/category'

const InitialState = {
    category: [],
    loadingCategory: false,
    filteredCategory: [],
    categoryDetails: {},
    isChangedCategory: false,
}

export const categoryReducer = (state = InitialState, action) => {
    switch (action.type) {
        case LOADING_CATEGORY: {
            return {
                ...state, loadingCategory: action.payload
            }
        }
        case GET_CATEGORY: {
            return {
                ...state, category: action.payload, filteredCategory: action.payload
            }
        }
        case FILTER_CATEGORY: {
            return {
                ...state, filteredCategory: state.category.filter((e) => {
                    return e.name.toLowerCase().includes(action.payload.toLowerCase())
                })
            }
        }

        case GET_CATEGORY_DETAILS: {
            return {
                ...state,
                categoryDetails: action.payload
            }
        }
        case IS_CHANGED_CATEGORY: {
            return {
                ...state,
                isChangedCategory: action.payload
            }
        }
        default:
            return state
    }
}
