import {
	LOADING_ORDER,
	GET_ORDER,
	FILTER_ORDER,
	FILTER_ORDER_BY_PICKER,
} from '../actionTypes/order'
import moment from 'moment'

const InitialState = {
	order: [],
	filteredOrder: [],
	// filteredOrderByPicker: [],
	loadingOategory: false,
}

export const orderReducer = (state = InitialState, action) => {
	switch (action.type) {
		case LOADING_ORDER: {
			return { ...state, loadingOrder: action.payload }
		}
		case GET_ORDER: {
			return {
				...state,
				order: action.payload,
				filteredOrder: action.payload,
				filteredOrderByPicker: action.payload,
			}
		}
		case FILTER_ORDER: {
			return {
				...state,
				filteredOrder: state.order.filter((e) => {
					return e.orderNumber.toString().includes(action.payload)
				}),
			}
		}
		case FILTER_ORDER_BY_PICKER: {
			return {
				...state,
				filteredOrder: state.order.filter((order) => {
					const parceTimeStamp = () => {
						const timeStamp =
							`${order.updateAt.seconds}${order.updateAt.nanoseconds}`.slice(
								0,
								13
							)

						return timeStamp.length < 13
							? Number(timeStamp + '00')
							: Number(timeStamp)
					}
					return action.payload !== null
						? (moment(parceTimeStamp()).isAfter(moment(action.payload[0])) &&
								moment(parceTimeStamp()).isBefore(moment(action.payload[1]))) ||
								moment(parceTimeStamp()).isSame(moment(action.payload[0])) ||
								moment(parceTimeStamp()).isSame(moment(action.payload[1]))
						: ' '
				}),
			}
		}
		default:
			return state
	}
}
