import React from 'react'
const SideBarIcon = ({ name }) => {
	// const image = localStorage.getItem(`assets%2FNavigationIcons%2F${name}.svg`)
	return (
		<img
			style={{ marginRight: '12px' }}
			src={`assets%2FNavigationIcons%2F${name}.svg`}
			alt={'name'}
			width='15px'
			height='15px'
		/>
	)
}

export default SideBarIcon
