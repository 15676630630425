import React from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router'
import HeaderMain from '../../components/HeaderSection/HeaderMain'
import Sidebar from '../../components/SideBarSection/Sidebar'
import { useSelector } from 'react-redux'
import { Layout } from 'antd'

const { Sider } = Layout

const ContentPage = () => {
	const { isAuth } = useSelector((state) => state.login)
	const location = useLocation()
	const navigate = useNavigate()
	// console.log('location', location)
	if (!isAuth) {
		// return navigate('/login', { state: { from: location } })
		return <Navigate to='/login' state={{ from: location }} replace />
	}
	// window.location.reload()

	return (
		<Layout style={{ minHeight: '100%' }}>
			<HeaderMain />
			<Layout>
				<Sider
					width={220}
					style={{
						overflow: 'auto',
						marginTop: '64px',
						position: 'fixed',
						left: 0,
						top: 0,
						bottom: 0,
						boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
						zIndex: 3,
					}}
				>
					<Sidebar />
				</Sider>
				<Layout
					style={{
						marginTop: '64px',
						marginLeft: '220px',
					}}
				>
					<Outlet />
				</Layout>
			</Layout>
		</Layout>
	)
}

export default ContentPage
