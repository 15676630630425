import React, {useEffect} from 'react';
import {PageHeader} from "antd";
import {Content} from "antd/es/layout/layout";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";
import {getProductDetails} from "../../../store/actions/products";

const ProductUpdate = () => {
    const dispatch = useDispatch();
    const {productOptions, productDetails} = useSelector(state => state.products);
    const params = useParams();
    const navigate = useNavigate();
    const {categoryDetails} = useSelector(state => state.category)

    console.log('productOptions', productOptions)
    console.log('productDetails', productDetails)

    useEffect(() => {
        dispatch(getProductDetails(params.id))
    }, [])

    const routes = [
        {path: 'products', breadcrumbName: 'Категорії'},
        {path: 'products', breadcrumbName: `${categoryDetails.name}`},
        {path: 'products', breadcrumbName: 'Зміна продукта'}
    ]
    return (
        <>
            <PageHeader
                breadcrumb={{routes}}
                title={productDetails.name}
            ></PageHeader>
            <Content style={{margin: 24, backgroundColor: "white"}}>

            </Content>
        </>
    );
};

export default ProductUpdate;