//thunks
import { collection, getDocs, query } from 'firebase/firestore/lite'
import { db } from '../../firebase'
import {
	GET_ORDER,
	LOADING_ORDER,
	FILTER_ORDER,
	FILTER_ORDER_BY_PICKER,
} from '../actionTypes/order'

export const getAllOrder = () => {
	return async (dispatch) => {
		dispatch(loaderOrder(true))
		const q = query(collection(db, 'orders'))
		const querySnapshot = await getDocs(q)
		if (querySnapshot) {
			const order = querySnapshot.docs.map((doc) => doc.data())
			dispatch(getOrder(order))
			dispatch(loaderOrder(false))
		}
	}
}

//actions
export const loaderOrder = (boolean) => {
	return {
		type: LOADING_ORDER,
		payload: boolean,
	}
}

export const getOrder = (order) => {
	return {
		type: GET_ORDER,
		payload: order,
	}
}

export const filterOrder = (searchText) => ({
	type: FILTER_ORDER,
	payload: searchText,
})

export const filterOrderByPicker = (searchText) => ({
	type: FILTER_ORDER_BY_PICKER,
	payload: searchText,
})
