import {
	signInWithEmailAndPassword,
	setPersistence,
	signOut,
	browserSessionPersistence,
	onAuthStateChanged,
} from 'firebase/auth'
import { auth, db } from '../../firebase'
import { query, collection, where, getDocs } from 'firebase/firestore/lite'
import {
	AUTH_USER,
	ERROR_LOGIN,
	GET_LOGO_URL,
	LOADING_LOGIN,
	USER_LOGIN,
} from '../actionTypes/login'

//thunks

export const loginUser = (userData) => {
	return async (dispatch) => {
		try {
			const user = await signInWithEmailAndPassword(
				auth,
				userData.email.trim(),
				userData.password.trim()
			)
			if (user) {
				const q = query(
					collection(db, 'admins'),
					where('email', '==', userData.email.trim())
				)
				const querySnapshot = await getDocs(q)
				if (querySnapshot._docs.length > 0) {
					if (!userData.remember) {
						setPersistence(auth, browserSessionPersistence)
					}
					dispatch(userLogin(user))
					dispatch(isAuthUser(true))
				} else {
					dispatch(errorLogin('permission error'))
				}
			}
		} catch (e) {
			dispatch(errorLogin())
		}
	}
}

export const loadUser = () => {
	return async (dispatch) => {
		try {
			dispatch(loadingLogin(true))

			// const auth = getAuth()
			// const user = auth.currentUser
			// console.log(user)
			onAuthStateChanged(auth, async (user) => {
				if (user) {
					const q = query(
						collection(db, 'admins'),
						where('email', '==', user.email)
					)

					const querySnapshot = await getDocs(q)
					if (querySnapshot._docs.length > 0) {
						dispatch(userLogin(user))
						dispatch(isAuthUser(true))
					} else {
						dispatch(userLogin(null))

						dispatch(loadingLogin(false))
					}
				} else {
					dispatch(loadingLogin(false))
				}
			})
		} catch (error) {
			dispatch(errorLogin('isd'))
			dispatch(loadingLogin(false))
		}
	}
}

export const logOut = () => {
	return async (dispatch) => {
		try {
			await signOut(auth)
			dispatch(userLogin(null))
			dispatch(isAuthUser(false))
		} catch (error) {
			dispatch(errorLogin(error.message))
		}
	}
}

export const loadingLogin = (boolean) => {
	return {
		type: LOADING_LOGIN,
		payload: boolean,
	}
}
export const isAuthUser = (boolean) => {
	return { type: AUTH_USER, payload: boolean }
}

//actions

const userLogin = (user) => ({ type: USER_LOGIN, payload: user })
const errorLogin = (textError) => ({ type: ERROR_LOGIN, payload: textError })
export const getLogoUrl = (url) => ({ type: GET_LOGO_URL, payload: url })
