import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import store from './store/store'
import './i18n'
import 'react-loading-skeleton/dist/skeleton.css'

// let title = document.getElementById("title-main");
// title.textContent = process.env.REACT_APP_TITLE

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<Provider store={store}>
		<App />
	</Provider>
)
