import {
	ERROR_LOGIN,
	USER_LOGIN,
	LOADING_LOGIN,
	AUTH_USER,
	GET_LOGO_URL,
} from '../actionTypes/login'
import { getAuth } from 'firebase/auth'

const auth = getAuth()

// const user = auth.currentUser
// console.log(user)
const InitialState = {
	user: auth.currentUser,
	error: '',
	loading: false,
	isAuth: null,
	logoUrl: '',
}

export const loginReducer = (state = InitialState, action) => {
	switch (action.type) {
		case USER_LOGIN:
			return { ...state, user: action.payload, loading: false }
		case ERROR_LOGIN:
			return { ...state, error: action.payload }
		case LOADING_LOGIN:
			return { ...state, loading: action.payload }
		case AUTH_USER:
			return { ...state, isAuth: action.payload }
		case GET_LOGO_URL:
			return { ...state, logoUrl: action.payload }
		default:
			return state
	}
}
