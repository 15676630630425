import React, { useEffect, useState } from 'react'
import {
	Button,
	Col,
	Layout,
	PageHeader,
	Row,
	message,
	Input,
	Select,
	Typography,
} from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import './CategoryAdd.css'
import { useNavigate } from 'react-router'
import { createCategory } from '../../../store/actions/category'
import Photo from '../../UI/Photo/Photo'
import { useTranslation } from 'react-i18next'
import {Content} from "antd/es/layout/layout";
const { Option } = Select
const { Text } = Typography

const CategoryAdd = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const routes = [
		{ path: 'category', breadcrumbName: `${t('category')}` },
		{ path: 'add', breadcrumbName: `${t('newCategoryBreadCrumb')}` },
	]
	const { category, isChangedCategory, loadingCategory } = useSelector(
		(state) => state.category
	)
	const [image, setImage] = useState('')
	const [name, setName] = useState('')
	const [status, setStatus] = useState('active')

	const messageSucces = (value) => {
		value === 'create' && message.success(t('CreateCategoryMessageSucces'))
	}

	const messageError = (value) => {
		value === 'create' && message.error(t('CreateCategoryMessageError'))
	}

	const addItem = () => {
		if (name.length > 0 && image) {
			let categoryData = {
				photoUrl: image,
				name: name,
				status: status,
				index: category.length ? category.length + 1 : 0,
			}
			dispatch(createCategory(categoryData, messageSucces, messageError))
		} else {
			message.error(t('CategoryAddVerification'))
		}
	}

	useEffect(() => {
		if (isChangedCategory) {
			navigate('/category')
		}
	}, [isChangedCategory])

	return (
		<>
			<PageHeader
				title={`${t('category')}`}
				breadcrumb={{ routes }}
			></PageHeader>
			<Content
			style={{
				margin: 24,
				backgroundColor: "white"
			}}>
				<Row>
					<Col span={8} offset={8} style={{ padding: '24px 0 24px 0' }}>
						<Photo onChange={(e) => setImage(e)} />
						<Text>{t('labelForInputName')}</Text>
						<Input
							value={name}
							style={{ margin: '10px 0 15px 0' }}
							placeholder='Введіть назву'
							onChange={(e) => setName(e.target.value)}
						/>
						<Text>{t('labelForInputStatus')}</Text>
						<Select
							style={{ margin: '10px 0 40px 0', width: '100%' }}
							defaultActiveFirstOption='true'
							defaultValue='active'
							onChange={(e) => setStatus(e)}
						>
							<Option id={1} value='active'>
								<img src='/assets/OtherIcons/circle_active.svg' /> Активний
							</Option>
							<Option id={2} value='not_active'>
								<img src='/assets/OtherIcons/circle_not_active.svg' /> Не
								активний
							</Option>
						</Select>
						<Button
							loading={loadingCategory}
							type='primary'
							onClick={addItem}
							style={{ marginRight: '10px' }}
						>
							{t('acceptChangeCategory')}
						</Button>
						<Button onClick={() => navigate('/category')}>
							{t('cancelChangeCategory')}
						</Button>
					</Col>
				</Row>
			</Content>
		</>
	)
}

export default CategoryAdd
