import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getAllOrder } from '../../store/actions/order'
import {
	PageHeader,
	DatePicker,
	Row,
	Col,
	Card,
	Typography,
	ConfigProvider,
} from 'antd'
import moment from 'moment'
import 'moment/locale/uk'
import 'moment/locale/ru'

const { Title, Text } = Typography

const Dashboard = () => {
	const { RangePicker } = DatePicker
	const { t } = useTranslation()
	const routes = [{ path: 'dashboard', breadcrumbName: `${t('main')}` }]
	const dispatch = useDispatch()
	const { order } = useSelector((state) => state.order)
	const { local } = useSelector((state) => state.local)
	const [price, setPrice] = useState()

	useEffect(() => {
		dispatch(getAllOrder())
	}, [])

	useEffect(() => {
		getIncome()
	}, [order])

	const getIncome = (value) => {
		setPrice(0)
		order.forEach((orderElement) => {
			const parceTimeStamp = () => {
				const timeStamp =
					`${orderElement.updateAt.seconds}${orderElement.updateAt.nanoseconds}`.slice(
						0,
						13
					)

				return timeStamp.length < 13
					? Number(timeStamp + '00')
					: Number(timeStamp)
			}
			if (value) {
				if (
					(moment(parceTimeStamp()).isAfter(moment(value[0])) &&
						moment(parceTimeStamp()).isBefore(moment(value[1]))) ||
					moment(parceTimeStamp()).isSame(moment(value[0])) ||
					moment(parceTimeStamp()).isSame(moment(value[1]))
				) {
					setPrice((prev) => (prev += orderElement.price.total))
				}
			} else {
				if (
					(moment(parceTimeStamp()).isAfter(moment().startOf('month')) &&
						moment(parceTimeStamp()).isBefore(moment().endOf('month'))) ||
					moment(parceTimeStamp()).isSame(moment().startOf('month')) ||
					moment(parceTimeStamp()).isSame(moment().endOf('month'))
				) {
					setPrice((prev) => (prev += orderElement.price.total))
				}
			}
		})
	}

	return (
		<>
			<PageHeader
				title={t('main')}
				className='site-page-header'
				breadcrumb={{ routes }}
				extra={[
					<ConfigProvider locale={local}>
						<RangePicker
							key={1}
							onChange={(value) => getIncome(value)}
							defaultValue={[
								moment().startOf('month'),
								moment().endOf('month'),
							]}
						/>
					</ConfigProvider>,
				]}
			/>

			<Row align='middle'>
				<Col lg={8} md={12} sm={24} xs={24} style={{ padding: '24px' }}>
					<Card style={{ padding: '10px 14px' }}>
						<Row gutter={[0, 15]} justify='space-between'>
							<Col>
								<Text>{t('cardIncomeText')}</Text>
							</Col>
							<Col>
								<img src='/assets/OtherIcons/iconDashboardCard.svg' />
							</Col>
							<Col span={24}>
								<Title level={2}>₴ {price}</Title>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col lg={8} md={12} sm={24} xs={24} style={{ padding: '24px' }}>
					<Card style={{ padding: '10px 14px' }}>
						<Row gutter={[0, 15]} justify='space-between'>
							<Col>
								<Text>{t('cardExpensesText')}</Text>
							</Col>
							<Col>
								<img src='/assets/OtherIcons/iconDashboardCard.svg' />
							</Col>
							<Col span={24}>
								<Title level={2}>₴ 600</Title>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default Dashboard
