import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button, Col, Input, PageHeader, Radio, Row} from 'antd'
import {useNavigate, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {filterProducts, getProductDetailsByCID, getProducts, UpdateIndexProduct} from "../../../store/actions/products";
import EmptyStateSearch from "../../UI/EmptyStateSearch/EmptyStateSearch";
import EmptyState from "../../UI/EmptyState/EmptyState";
import CategoryList from "../../CategorySection/Category/CategoryList/CategoryList";
import Skeleton from "react-loading-skeleton";
import ProductsCards from "./ProductsCards/ProductsCards";
import {getCategoryDetails} from "../../../store/actions/category";
import {Content} from "antd/es/layout/layout";

const {Search} = Input

const Product = () => {
    const [toggleViewCategory, setToggleViewCategory] = useState('a')
    const {t} = useTranslation()
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {products, loadingProducts, filteredProducts} = useSelector(state => state.products)
    const {categoryDetails} = useSelector(state => state.category)

    const routes = [
        {path: 'category', breadcrumbName: `${t('category')}`},
        {path: 'category', breadcrumbName: `${categoryDetails.name}`},
    ]

    useEffect(() => {
        dispatch(getProductDetailsByCID(params.id))
        dispatch(getCategoryDetails(params.id))
    }, [])


    const editProduct = (id) => {
        navigate(`/category/${id}/product/edit`)
    }

    const addNewProduct = (id) => {
        navigate(`/category/${id}/product/add`)
    }

    const updateIndexProduct = (newData, messageSucces, messageError) => {
        dispatch(UpdateIndexProduct(newData, messageSucces, messageError))
        dispatch(getProducts(newData))
    }

    const skeletonSize = [1, 2, 3, 4, 5, 6, 7, 8];

    return (
        <>
            <PageHeader
                title={`${categoryDetails.name}`}
                breadcrumb={{routes}}
                extra={[filteredProducts.length ? <Button type='primary' onClick={() => addNewProduct(categoryDetails.id)}>+ {t('headerAddButton')}</Button> : null]}
            >
                <Row>
                    <Col
                        span={24}
                        style={{display: 'flex', justifyContent: 'space-between'}}
                    >
                        <Col
                            xxl={{span: 8, offset: 8}}
                            xl={{span: 8, offset: 8}}
                            lg={{span: 10, offset: 6}}
                            md={{span: 14}}
                            sm={{span: 16}}
                            xs={{span: 20}}
                        >
                            <Search
                                placeholder={t('searchPlaceholder')}
                                allowClear
                                enterButton={t('searchEnterButton')}
                                size='medium'
                                onChange={(e) => dispatch(filterProducts(e.target.value))}
                            />
                        </Col>
                        {filteredProducts.length ?
                            <Radio.Group
                                onChange={(value) => setToggleViewCategory(value.target.value)}
                                defaultValue='a'
                                buttonStyle='solid'
                            >
                                <Radio.Button value='a'>
                                    <img
                                        src={`/assets/OtherIcons/grid_${
                                            toggleViewCategory === 'b' ? 'yellow' : 'white'
                                        }.svg`}
                                        alt=''
                                    />
                                </Radio.Button>
                                <Radio.Button value='b'>
                                    <img
                                        src={`/assets/OtherIcons/list_${
                                            toggleViewCategory === 'a' ? 'yellow' : 'white'
                                        }.svg`}
                                        alt=''
                                    />
                                </Radio.Button>
                            </Radio.Group> : null
                        }
                    </Col>
                </Row>
            </PageHeader>
            <Content style={{margin: 24}}>
            {!loadingProducts ?
                toggleViewCategory === 'a'
                    ? filteredProducts.length
                        ? <ProductsCards
                            editProduct={editProduct}
                            filteredProducts={filteredProducts}
                        />
                        : products.length ? <EmptyStateSearch/> : <EmptyState addItem={addNewProduct}/>
                    : filteredProducts.length
                        ? <CategoryList
                            editCategory={editProduct}
                            updateIndex={updateIndexProduct}
                            category={filteredProducts}/>
                        : products.length ? <EmptyStateSearch/> : <EmptyState addItem={addNewProduct}/>
                : <Row gutter={[16, 16]} style={{padding: '22px 24px'}}> {skeletonSize.map((value) => {
                    return <Col key={value} xxl={4} xl={6} lg={8} md={12} sm={12} xs={24}>
                        <Skeleton
                            key={value}
                            inline
                            height={203}
                            count={1}
                        />
                    </Col>
                })
                }
                </Row>
            }
            </Content>
        </>
    )
}

export default Product;
