import {
	collection,
	getDocs,
	query,
	updateDoc,
	deleteDoc,
	doc,
	addDoc,
	getDoc,
	where,
} from 'firebase/firestore/lite'
import { db, storage } from '../../firebase'
import {
	FILTER_CATEGORY,
	GET_CATEGORY,
	GET_CATEGORY_DETAILS,
	IS_CHANGED_CATEGORY,
	LOADING_CATEGORY,
} from '../actionTypes/category'
import {
	ref,
	uploadBytesResumable,
	getDownloadURL,
	deleteObject,
} from 'firebase/storage'

export const getAllCategory = () => {
	return async (dispatch) => {
		try {
			dispatch(loaderCategory(true))
			const q = query(collection(db, 'categories'))
			const querySnapshot = await getDocs(q)
			if (querySnapshot) {
				const category = querySnapshot.docs.map((doc) => doc.data())
				dispatch(loaderCategory(false))
				dispatch(getCategory(category.sort((a, b) => a.index - b.index)))
			}
		} catch (error) {
			dispatch(loaderCategory(false))
		}
	}
}

export const UpdateIndexCategory =
	(data, messageSucces, messageError) => async (dispatch) => {
		try {
			for (let v = 0; v < data.length; v++) {
				await updateDoc(doc(db, 'categories', data[v].id), {
					index: v + 1,
				})
				if (data.length === v + 1) {
					messageSucces('updateCategoryIndex')
				}
			}
		} catch (error) {
			messageError('updateCategoryIndex')
		}
	}

export const createCategory =
	(categoryData, messageSucces, messageError) => async (dispatch) => {
		try {
			dispatch(loaderCategory(true))
			const docRef = await addDoc(collection(db, 'categories'), {
				name: categoryData.name,
				status: categoryData.status,
				index: categoryData.index,
			})

			if (docRef) {
				if (docRef.id) {
					const storageRef = ref(
						storage,
						`/categories/${
							docRef.id
						}/category_image.${categoryData.photoUrl.type.split('/').pop()}`
					)
					const uploadTask = uploadBytesResumable(
						storageRef,
						categoryData.photoUrl
					)
					let originimgName = `category_image.${categoryData.photoUrl.type
						.split('/')
						.pop()}`
					uploadTask.on(
						'state_changed',
						(snapshot) => {
							const prog = Math.round(
								(snapshot.bytesTransferred / snapshot.totalBytes) * 100
							)
						},
						(err) => console.log(err),
						() => {
							getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
								if (url) {
									const newCategoryRef = await updateDoc(
										doc(db, 'categories', docRef.id),
										{
											id: docRef.id,
											photoUrl: url,
											originalImagename: originimgName,
										}
									)
									messageSucces('create')
									dispatch(isChangedCategory(true))
									dispatch(loaderCategory(false))
								}
							})
						}
					)
				}
			}
		} catch (error) {
			messageError('create')
		}
	}

export const getCategoryDetails = (id) => async (dispatch) => {
	try {
		const docSnap = await getDoc(doc(db, 'categories', id))
		dispatch(categoryDetails(docSnap.data()))
	} catch (error) {}
}

// Update Category
export const updateCategory =
	(id, categoryData, messageError, messageSucces) => async (dispatch) => {
		try {
			dispatch(loaderCategory(true))
			if (typeof categoryData.photoUrl == 'object') {
				const deleteRef = ref(
					storage,
					`/categories/${id}/${categoryData.originalImagename}`
				)

				deleteObject(deleteRef)
					.then(async () => {
						const storageRef = ref(
							storage,
							`/categories/${id}/category_image.${categoryData.photoUrl.type
								.split('/')
								.pop()}`
						)
						let originimgName = `category_image.${categoryData.photoUrl.type
							.split('/')
							.pop()}`
						const uploadTask = uploadBytesResumable(
							storageRef,
							categoryData.photoUrl
						)
						uploadTask.on(
							'state_changed',
							(snapshot) => {
								const prog = Math.round(
									(snapshot.bytesTransferred / snapshot.totalBytes) * 100
								)
							},
							(err) => console.log(err),
							() => {
								getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
									if (url) {
										await updateDoc(doc(db, 'categories', id), {
											name: categoryData.name,
											status: categoryData.status,
											originalImagename: originimgName,
											photoUrl: url,
										})
										messageSucces('update')
										dispatch(loaderCategory(false))
										dispatch(isChangedCategory(true))
									}
								})
							}
						)
					})
					.catch((error) => {
						messageError('update')
						dispatch(loaderCategory(false))
					})
			} else {
				await updateDoc(doc(db, 'categories', id), categoryData)
				messageSucces('update')
				dispatch(loaderCategory(false))
				dispatch(isChangedCategory(true))
			}
		} catch (error) {
			messageError('update')
			dispatch(loaderCategory(false))
		}
	}

// Delete Category
export const deleteCategory =
	(imageName, id, messageError, messageSucces) => async (dispatch) => {
		try {
			dispatch(loaderCategory(true))
			// Delete Category
			let deleteMaincategory = () => {
				// Create a reference to the file to delete
				imageRef = ref(storage, 'categories/' + id + '/' + imageName)

				// Delete the file
				deleteObject(imageRef)
					.then(async () => {
						await deleteDoc(doc(db, 'categories', id))
						dispatch(isChangedCategory(true))
						dispatch(loaderCategory(false))
						messageSucces('del')
					})
					.catch((error) => {
						dispatch(loaderCategory(false))
						messageError('del')
					})
			}

			// For products
			const q = query(collection(db, 'products'), where('categoryId', '==', id))
			const querySnapshot = await getDocs(q)
			let imageRef, productId
			if (querySnapshot._docs.length > 0) {
				await querySnapshot.forEach(async (res) => {
					imageRef = ref(
						storage,
						'products/' + res.data().id + '/' + res.data().originalImagename
					)

					productId = res.data().id
					deleteObject(imageRef)
						.then((result) => {})
						.catch((error) => console.log(error))

					const oprionSnap = await getDocs(
						collection(db, 'products/' + res.data().id + '/options')
					)

					oprionSnap.docs.map(async (result) => {
						await deleteDoc(
							doc(
								db,
								'products/' + res.data().id + '/options/',
								result.data().optionId
							)
						)
					})
					await deleteDoc(doc(db, 'products', res.data().id))
				})
				deleteMaincategory()
			} else {
				deleteMaincategory()
			}
		} catch (error) {}
	}

//actions

export const loaderCategory = (boolean) => ({
	type: LOADING_CATEGORY,
	payload: boolean,
})
export const getCategory = (category) => ({
	type: GET_CATEGORY,
	payload: category,
})
export const filterCategory = (searchText) => ({
	type: FILTER_CATEGORY,
	payload: searchText,
})
export const categoryDetails = (categoryDetails) => ({
	type: GET_CATEGORY_DETAILS,
	payload: categoryDetails,
})
export const isChangedCategory = (boolean) => ({
	type: IS_CHANGED_CATEGORY,
	payload: boolean,
})
