import React from 'react';

const Ingredients = () => {
    return (
        <div>
            
        </div>
    );
};

export default Ingredients;