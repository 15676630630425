import React from 'react';
import {Button, Card, Col, Row, Typography} from "antd";
import Meta from "antd/es/card/Meta";
const {Title, Text} = Typography

const ProductsCards = (props) => {
    return (
        <>
          <Row gutter={[16, 24]}>
              {props.filteredProducts.map((product) => {
                  return <Col key={product.id} xxl={4} xl={6} lg={8} md={12} sm={12} xs={24}>
                      <Card
                          hoverable
                          cover={
                              <img alt="example"
                                   src={product.photoUrl}
                                   style={{objectFit: "cover", width: '100%', height: '150px'}}
                              />}
                      >
                          <Row justify={"space-between"} style={{padding: '10px 0'}} align={'middle'}>
                              <Col>
                                  <Row align={'middle'}>
                                      <img src={`/assets/OtherIcons/circle_${product.status}.svg`} style={{
                                          marginRight: '8px'
                                      }}/>
                                      <Title level={5}>{product.name}</Title>
                                  </Row>
                              </Col>
                              <Col>
                                  <Button type='primary' onClick={(e) => {
                                      e.stopPropagation()
                                      props.editProduct(product.id)
                                  }} icon={<img src='/assets/OtherIcons/edit_yellow.svg'/>}/>
                              </Col>
                          </Row>
                          <Meta
                                description={<Text>{product.price + ' грн'}</Text>}
                          />

                      </Card>
                  </Col>
              })}
          </Row>
      </>
    );
};

export default ProductsCards;