import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import {
	getAllOrder,
	filterOrder,
	filterOrderByPicker,
} from '../../../store/actions/order'
import {
	PageHeader,
	DatePicker,
	Row,
	Col,
	ConfigProvider,
	Input,
	Table,
} from 'antd'
import { Content } from 'antd/es/layout/layout'
import EmptyState from '../../UI/EmptyState/EmptyState'
import EmptyStateSearch from '../../UI/EmptyStateSearch/EmptyStateSearch'
import Skeleton from 'react-loading-skeleton'
import OrderList from './OrderList'
import moment from 'moment'
import 'moment/locale/uk'
import 'moment/locale/ru'

const { Search } = Input

const Orders = () => {
	const { RangePicker } = DatePicker
	const { t } = useTranslation()
	const routes = [{ path: 'oreder', breadcrumbName: `${t('order')}` }]
	const dispatch = useDispatch()
	const { order, filteredOrder, loadingOrder } = useSelector(
		(state) => state.order
	)
	const { local } = useSelector((state) => state.local)
	const navigate = useNavigate()

	useEffect(() => {
		dispatch(getAllOrder())
	}, [])

	const addNewOrder = () => {
		navigate('/order/add')
	}

	const skeletonSize = [1, 2, 3, 4, 5, 6, 7, 8]

	const columns = [
		{
			title: '№',
			dataIndex: 'orderNumber',
			key: 'orderNumber',
			width: '50px',
			render: () => (
				<Skeleton style={{ width: '100%' }} active={true} size='default' />
			),
		},
		{
			title: `${t('orderColumnsDateTitle')}`,
			dataIndex: 'updateAt',
			key: 'updateAt',
			width: '200px',
			render: () => (
				<Skeleton style={{ width: '100%' }} active={true} size='default' />
			),
		},
		{
			title: `${t('listColumnsStatusTitle')}`,
			dataIndex: 'status',
			key: 'status',
			width: '200px',
			render: () => (
				<Skeleton style={{ width: '100%' }} active={true} size='default' />
			),
		},

		{
			title: `${t('orderColumnsNameTitle')}`,
			dataIndex: 'name',
			key: 'name',
			width: '250px',
			render: () => (
				<Skeleton style={{ width: '100%' }} active={true} size='default' />
			),
		},
		{
			title: `${t('orderColumnsTypePayTitle')}`,
			dataIndex: 'paymentType',
			key: 'paymentType',
			width: '180px',
			render: () => (
				<Skeleton style={{ width: '100%' }} active={true} size='default' />
			),
		},
		{
			title: `${t('orderColumnsCashTitle')}`,
			dataIndex: 'total',
			key: 'total',
			width: '90px',
			render: () => (
				<Skeleton style={{ width: '100%' }} active={true} size='default' />
			),
		},
	]

	return (
		<>
			<PageHeader
				title={t('order')}
				className='site-page-header'
				breadcrumb={{ routes }}
				extra={[
					<ConfigProvider locale={local}>
						<RangePicker
							key={1}
							onChange={(value) => {
								dispatch(filterOrderByPicker(value))
							}}
						/>
					</ConfigProvider>,
				]}
			>
				<Row>
					<Col
						span={24}
						style={{ display: 'flex', justifyContent: 'space-between' }}
					>
						<Col
							xxl={{ span: 8, offset: 8 }}
							xl={{ span: 8, offset: 8 }}
							lg={{ span: 10, offset: 6 }}
							md={{ span: 14 }}
							sm={{ span: 16 }}
							xs={{ span: 20 }}
						>
							<Search
								placeholder={t('searchPlaceholderForOrder')}
								allowClear
								enterButton={t('searchEnterButton')}
								size='medium'
								onChange={(e) => dispatch(filterOrder(e.target.value))}
							/>
						</Col>
					</Col>
				</Row>
			</PageHeader>
			<Content
				style={{
					margin: 24,
				}}
			>
				{!loadingOrder ? (
					filteredOrder.length ? (
						<ConfigProvider locale={local}>
							<OrderList
								// navigateToProducts={navigateToProducts}
								// editOrder={editOrder}
								// updateIndex={updateIndexOrder}
								order={filteredOrder}
							/>
						</ConfigProvider>
					) : order.length ? (
						<EmptyStateSearch />
					) : (
						<EmptyState />
					)
				) : (
					<Table
						pagination={false}
						columns={columns}
						dataSource={order}
						scroll={{
							y: '100vh',
						}}
					/>
				)}
			</Content>
		</>
	)
}

export default Orders
