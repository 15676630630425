import React, {useState} from 'react';
import {Button, Modal, Row} from "antd";
import {useTranslation} from "react-i18next";

const ModalDelete = (props) => {
    const [visible, setVisible] = useState(false);

    const {t} = useTranslation()

    const showModal = () => {
        setVisible(true);
    };

    const hideModal = () => {
        setVisible(false);
    };

    const handleOk = () => {
        props.deleteFunction()
        hideModal()
    }

    return (
        <>
            <Button loading={props.loadingCategory} danger type='link' onClick={showModal}>
                <Row align={'middle'}><img src='/assets/OtherIcons/trash_red.svg' alt="Icon"
                                           style={{padding: '10px'}}/>{t('deleteCategory')}</Row>
            </Button>
            <Modal
                title={t('confirmationDeleteCategory')}
                visible={visible}
                onOk={handleOk}
                onCancel={hideModal}
                okText={t('acceptDeleteCategory')}
                cancelText={t('cancelDeleteCategory')}
            >
                <p>{t('questionAboutDeleteCategory')}</p>
            </Modal>
        </>
    );
};

export default ModalDelete;