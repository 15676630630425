import React from 'react';
import {Col, Row, Typography} from "antd";
const {Text} = Typography

const EmptyStateSearch = () => {


    return (
        <Row justify={'center'} align={'middle'} style={{height: 'calc(100% - 142px)'}}>
            <Col>
                <Row style={{paddingBottom: '58px', justifyContent: "center"}}>
                    <img src='/assets/img/emty_second.png'/></Row>
                <Row justify={'center'} style={{maxWidth: '320px', textAlign: 'center'}}>
                    <Text>Нічого не знайдено. Перевірте коректність введених даних і спробуйте ще раз</Text>
                </Row>

            </Col>
        </Row>
    );
};

export default EmptyStateSearch;