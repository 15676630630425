import {
    FILTER_PRODUCTS,
    GET_PRODUCT_DETAILS,
    GET_PRODUCT_OPTIONS,
    GET_PRODUCTS,
    LOADING_PRODUCTS
} from "../actionTypes/products";


const InitialState = {
    products: [],
    filteredProducts: [],
    loadingProducts: false,
    productDetails: {},
    productOptions: []
}

export const productsReducer = (state = InitialState, action) => {
    switch (action.type) {
        case LOADING_PRODUCTS:
            return {
                ...state,
                loadingProducts: action.payload
            }
        case GET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                filteredProducts: action.payload
            }
        case FILTER_PRODUCTS: {
            return  {
                ...state,
                filteredProducts: state.products.filter((e) => {
                    return e.name.toLowerCase().includes(action.payload.toLowerCase())
                })
            }
        }
        case GET_PRODUCT_DETAILS: {
            return {...state,
            productDetails: action.payload
            }
        }
        case GET_PRODUCT_OPTIONS: {
            return  {
                ...state,
                productOptions: action.payload
            }
        }
        default:
            return state
    }
}
